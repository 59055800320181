<template>
  <b-card>
    <b-row>
      <b-col>
        <span class="ads-name rounded px-3 py-1 text-center"
          >الأعلان {{ index + 1 }}</span
        >
      </b-col>
      <b-col cols="2">
        <b-dropdown size="sm" variant="link" no-caret>
          <template #button-content>
            <b-icon
              icon="three-dots-vertical"
              animation="cylon-vertical"
            ></b-icon>
          </template>
          <b-dropdown-item @click="$emit('update')">
            <b-icon icon="pencil-square" scale="0.5" class="ml-2"></b-icon
            >تعديل</b-dropdown-item
          >
          <b-dropdown-item @click="$emit('remove')">
            <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
            حذف</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
    </b-row>
    <img :src="ads.image" class="w-100 my-3" height="200" />
    <b-row class="mb-3">
      <b-col>
        <span class="p-1 rounded bg-success ml-2"></span>
        <span>تاريخ البدء</span>
      </b-col>
      <b-col cols="4" class="text-left">
        <span> {{ new Date(ads.start).toISOString().slice(0, 10) }} </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="p-1 rounded bg-orange ml-2"></span>
        <span>تاريخ الأنتهاء"</span>
      </b-col>
      <b-col cols="4" class="text-left">
        <span> {{ new Date(ads.end).toISOString().slice(0, 10) }} </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    ads: Object,
    index: Number,
  },
};
</script>

<style>
.ads-name {
  color: #14c25a;
  background-color: rgba(20, 194, 90, 0.1);
}
.bg-orange {
  background-color: #ff601f;
}
</style>
