<template>
  <VContent>
    <template slot="right">
      <span>قائمة الأعلانات</span>
    </template>
    <template slot="left">
      <b-button variant="info" size="md" class="text-light" v-b-modal.ads-modal
        >اضافة</b-button
      >
    </template>
    <template slot="content">
      <b-row>
        <b-col
          cols="4"
          v-for="(item, index) in ads"
          :key="item.id"
          class="mb-3"
        >
          <AdsCard
            :ads="item"
            :index="index"
            @update="getByID(item.id)"
            @remove="remove(item.id)"
          ></AdsCard>
        </b-col>
      </b-row>
    </template>
    <template slot="modals">
      <AdsModal></AdsModal>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import { successAlert, errorAlert, showAlertWithConfirm } from "@/utils/helper";
import AdsCard from "./components/adsCard.vue";
import AdsModal from "./components/adsModal.vue";

export default {
  components: {
    AdsCard,
    AdsModal,
  },
  data() {
    return { page: 1 };
  },
  computed: {
    ads() {
      return this.$store.state.admin.ads.list;
    },
    meta() {
      return this.$store.state.admin.ads.meta;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("admin/ads/get", this.page);
    },
    getByID(id) {
      this.$store
        .dispatch("admin/ads/show", id)
        .then(() => {
          this.readonly = false;
          this.$bvModal.show("ads-modal");
        })
        .catch(() => errorAlert());
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("admin/ads/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("admin/ads/DELETE_ADS", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
