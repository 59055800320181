<template>
  <b-modal
    id="ads-modal"
    :title="form.id ? 'تحديث بيانات الأعلان' : 'أضافة أعلان'"
    shadow
    hide-footer
    :no-close-on-backdrop="true"
    @hidden="clearForm"
    size="md"
    body-class="p-5"
  >
    <div class="px-3 py-2">
      <VForm v-slot="{ onValidate }">
        <VFileField
          v-model="form.image"
          label="صورة الغلاف"
          type=".jpg, .png"
          :disabled="false"
        ></VFileField>
        <VDateField
          v-model="form.start"
          label="تاريخ البدء"
          rules="required"
        ></VDateField>
        <VDateField
          v-model="form.end"
          label="تاريخ الأنتهاء"
          rules="required"
        ></VDateField>
        <b-row>
          <b-col>
            <b-button
              :variant="form.id ? 'success' : 'info'"
              block
              @click="onValidate(action)"
              >{{ form.id ? "تحديث" : "أضافة" }}</b-button
            >
          </b-col>
          <b-col>
            <b-button
              variant="outline-info"
              block
              @click="$bvModal.hide('ads-modal')"
              >الغاء</b-button
            >
          </b-col>
        </b-row>
      </VForm>
    </div>
  </b-modal>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";
export default {
  props: {
    ads: Object,
  },
  computed: {
    form: {
      get() {
        return this.$store.state.admin.ads.form;
      },
      set(value) {
        this.$store.commit("admin/ads/SET_ADS", value);
      },
    },
  },
  methods: {
    action() {
      var state = this.form.id ? "update" : "store";
      this.$store
        .dispatch(`admin/ads/${state}`)
        .then((response) => {
          successAlert();
          this.$bvModal.hide("ads-modal");
          this.$store.commit(
            `admin/ads/${state.toUpperCase()}_ADS`,
            response.payload
          );
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>
